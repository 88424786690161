import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div className="footer">
<div>
<h1>جميع الحقوق محفوظة © 2024 وحدة مكافحة غسل الاموال وتمويل الارهاب لارمو.</h1> 

</div>
<div>
  <h1>
<a target='_blank' href="https://wa.me/+201026270790">  تصميم وتطوير : <span>NG-Technology</span> </a>

  </h1>
</div>
 </div>
      )
}

export default Footer