import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements
} from "react-router-dom";
import Sign from './Pages/Sign/Sign';
import Login from './Pages/Login/Login';
import Creat from './Pages/Creat/Creat';
import _ReportPage from './Pages/_RepoptPage/RepoptPage';
import Created from './Pages/Created/Created';
import ViewReport from './Pages/ViewReport/ViewReport';
import { AuthProvider } from './Context/AuthProvider';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />} />
      <Route path="sign" element={<Sign />} />
      <Route path="login" element={<Login />} />
      <Route path="creat" element={<Creat />} />
      <Route path="reportPage" element={<_ReportPage />} />
      <Route path="created" element={<Created />} />
      <Route path="viewreport" element={<ViewReport />} />
      <Route path="*" element={<div>404 - Page Not Found</div>} />
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);

reportWebVitals();
